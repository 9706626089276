import bg1 from "../../../assets/images/bg1.jpg";
import img3 from "../../../assets/images/img3.png";
import translate from "i18nProvider/translate";
import { injectIntl } from "react-intl";

const itemsCarousel = [
  { image: bg1, active: "active", desc: "" },
  { image: img3, active: "", desc: "" },
  { image: img3, active: "", desc: "" },
];

const MainSection = injectIntl(() => {
  return (
    <section id="hero">
      <div
        id="heroCarousel"
        className="carousel slide carousel-fade"
        data-ride="carousel"
      >
        <div className="carousel-inner" role="listbox">
          {itemsCarousel.map((item, i) => (
            <div
              className={`carousel-item ${item.active}`}
              style={{ backgroundImage: `url(${item.image})` }}
              key={i}
            >
              <div className="carousel-container">
                <div className="carousel-content animate__animated animate__fadeInUp">
                  <h2>
                    {translate("welcome")} <span>Church en Linea</span>
                  </h2>
                  <p>
                    {translate("welcomeDesc")}
                  </p>
                  <div className="text-center">
                    <a href="!#" className="btn-get-started">
                      {translate("readMore")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <a
          className="carousel-control-prev"
          href="#heroCarousel"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon icofont-simple-left"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>

        <a
          className="carousel-control-next"
          href="#heroCarousel"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon icofont-simple-right"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>

        <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>
      </div>
    </section>
  );
});

export default MainSection;
