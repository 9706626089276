import React from "react";

export const useLanguage = () => {
  const [language, setCLanguage] = React.useState(localStorage.getItem("lang"));

  const setLanguage = (lang) => {
    setCLanguage(lang);
    localStorage.setItem("lang", lang);
  };
  return { language, setLanguage };
};

export const LanguageContext = React.createContext({
  language: "",
  setLanguage: () => {},
});
