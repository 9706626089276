import { createIntl, createIntlCache } from "react-intl";
import messages from "./messages";

const cache = createIntlCache();

const translate = (id, value = {}) => {
  const locale = localStorage.getItem("lang");
  const intl = createIntl(
    {
      locale: locale,
      messages: messages[locale],
    },
    cache
  );
  return intl.formatMessage({ id: id });
};

export default translate;
