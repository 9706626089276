import React from "react";
import "./header.css";
import logo from "assets/images/dc-logo.png";
import translate from "i18nProvider/translate";
import { LanguageContext } from "globalContext/globalContext";

const Header = () => {
  const { setLanguage } = React.useContext(LanguageContext);

  return (
    <header id="header" className="fixed-top">
      <div className="navContainer">
        <nav className="navbar navbar-expand-lg navbar-light">
          <img src={logo} alt="logo" />
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="#about-us">
                  {translate("aboutUs")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#services">
                  {translate("features")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#pricing">
                  {translate("pricing")}
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="!#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="bx bx-world"></i>
                  {translate("language")}
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a
                    className="dropdown-item"
                    href="!#"
                    onClick={() => setLanguage("es-es")}
                  >
                    {translate("spanish")}
                  </a>
                  <a
                    className="dropdown-item"
                    href="!#"
                    onClick={() => setLanguage("en-us")}
                  >
                    {translate("english")}
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://app.churchenlinea.com">
                  {translate("signIn")}
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
