import React from "react";
import {
  Header,
  MainSection,
  Features,
  Footer,
  PricingTable,
  About,
} from "./components";
import { I18nPropvider } from "i18nProvider";
import { useLanguage, LanguageContext } from "globalContext/globalContext";

const LandingPage = () => {
  const { language, setLanguage } = useLanguage();

  return (
    <LanguageContext.Provider value={{ language, setLanguage: setLanguage }}>
      <I18nPropvider locale={language}>
        <>
          <Header />
          <MainSection />
          <Features />
          <PricingTable />
          <About />
          <Footer />
        </>
      </I18nPropvider>
    </LanguageContext.Provider>
  );
};

export default LandingPage;
