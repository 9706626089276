import React from "react";
import ReactDOM from "react-dom";
import { LandingPage } from "./pages";
import reportWebVitals from "./reportWebVitals";

const lang = localStorage.getItem("lang");
if (!lang) {
  localStorage.setItem("lang", "es-es");
}

ReactDOM.render(
  <React.StrictMode>
    <LandingPage />
  </React.StrictMode>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
