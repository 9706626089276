import translate from "i18nProvider/translate";

const PricingTable = () => {
  const pricingOptions = [
    {
      icon: "fa fa-user",
      title: "Personal",
      features: ["freeCreateChurch","freeCreateProgram","freeUsers", "reports"],
      cost: translate("free"),
      action: "signUp",
    },
    // {
    //   icon: "fa fa-plane",
    //   title: "small team",
    //   features: ["Never sleeps", "Multiple workers for more powerful apps"],
    //   cost: "$150",
    //   action: "freeTrial",
    // },
    // {
    //   icon: "fa fa-paper-plane",
    //   title: "enterprise",
    //   features: ["Simple horizontal scalability", "Dedicated"],
    //   cost: "$250",
    //   action: "freeTrial",
    // },
  ];
  return (
    <section id="pricing" className="pricing">
      <div class="section-title">
        <h2>{translate("pricing")}</h2>
      </div>
      <div className="container" data-aos="fade-up">
        <div className="row d-flex justify-content-center">
          {pricingOptions.map((option, i) => (
            <div className="col-lg-3 col-md-6 mt-4 mt-md-0" key={i}>
              <div className="box featured">
                <h3>{option.title}</h3>
                <h4>
                  <sup>$</sup>
                  {option.cost}
                  <span> / {translate("month")}</span>
                </h4>
                <ul>
                  {option.features.map((op, x) =>(
                    <li>{translate(op)}</li>
                  ))}
                  {/* <li className="na">Massa ultricies mi</li> */}
                </ul>
                <div className="btn-wrap">
                  <a href="https://app.churchenlinea.com/create-account" className="btn-buy">
                    {translate(option.action)}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingTable;
