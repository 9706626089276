import { LOCALES } from "../constants";

// eslint-disable-next-line
export default {
  [LOCALES.ENGLISH]: {
    home: "Home",
    aboutUs: "About us",
    services: "Services",
    contact: "Contact",
    language: "Language",
    welcome: "Welcome to",
    welcomeDesc:
      "Welcome to church Online where you can take the organization of the church and you can keep abreast of everything that happens in your church. God bless you",
    english: "English",
    spanish: "Spanish",
    members: "Members",
    membersDesc:
      "Manage all the information about your church members in an easy way",
    transactions: "Transactions",
    transactionsDesc:
      "Manage all the tranasactions of your church in a safe way",
    groups: "Groups",
    groupsDesc:
      "Create different groups with the members of your church assigning different responsibilities",
    programs: "Programs",
    programsDesc:
      "Create and plan the different programs to be executed by your church",
    free: "Free",
    personal: "Personal",
    fewMembers: "Few Members",
    manyMembers: "Many Members",
    freeTrial: "Free trial",
    signUp: "Sign Up",
    signIn: "Sign In",
    pricing: "Pricing",
    features: "Features",
    readMore: "Read More",
    reports: "Reports",
    reportsDesc: "Create, view and download your church reports",
    users: "Users",
    usersDesc: "Create, modify and delete a user in your church",
    month: "Month",
    freeCreateChurch:"Create Churchs",
    freeCreateProgram:"Create Programs",
    freeUsers:"Create Users"
  },
};
