import { LOCALES } from "../constants";

// eslint-disable-next-line
export default {
  [LOCALES.SPANISH]: {
    home: "Inicio",
    aboutUs: "Quienes somos",
    services: "Servicios",
    contact: "Contactanos",
    language: "Idioma",
    welcome: "Bienvenido a",
    welcomeDesc:
      "Bienvenido a church En linea donde podras llevar la organizacion de la iglesia y podras mantenerte al tanto de todo lo que ocurre en tu iglesia. Dios te bendiga",
    english: "Ingles",
    spanish: "Español",
    members: "Miembros",
    membersDesc:
      "Maneja todas la informacion de los miembros de una manera facil y practica",
    transactions: "Transacciones",
    transactionsDesc: "Maneja y registra todas las transacciones de la iglesia",
    groups: "Grupos",
    groupsDesc: "Crea y maneja los diferentes grupos de tu iglesia",
    programs: "Programas",
    programsDesc:
      "Crea y maneja los programas a ser llevados a cabo por la iglesia",
    free: "Gratis",
    personal: "Personal",
    fewMembers: "Pocos Miembros",
    manyMembers: "Muchos Miembros",
    freeTrial: "Prueba Gratis",
    signUp: "Registrarse",
    signIn: "Iniciar sesion",
    pricing: "Precios",
    features: "Herramientas",
    readMore: "Leer Mas",
    reports: "Reportes",
    reportsDesc: "Crear, visualizar y descargar los reportes de tu iglesia",
    users: "Usuarios",
    usersDesc: "Crear, modificar y eliminar algun usuario en tu iglesia",
    month: "Mes",
    freeCreateChurch:"Creacion de iglesia",
    freeCreateProgram:"Creacion de programas",
    freeUsers:"Creacion de usuarios"

  },
};
